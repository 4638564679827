<template>
  <div>
    <el-autocomplete v-model="name" :fetch-suggestions="querySearchAsync" placeholder="请输入院校信息" @select="handleSelect">
      <template slot-scope="{ item }">
        <div class="name">{{ item.name }}</div>
      </template>
    </el-autocomplete>
    <span style="margin-left: 20px"> 服从调剂 </span>
    <el-radio-group v-if="itemData.college" v-model="itemData.college.tiaoji">
      <el-radio :label="1">是</el-radio>
      <el-radio :label="0">否</el-radio>
    </el-radio-group>

    <el-table :data="tableData" border height="350" style="margin-top: 20px">
      <el-table-column prop="specialty_id" label="专业代码" width="180">
      </el-table-column>
      <el-table-column prop="specialty_name" label="专业" width="180">
      </el-table-column>
      <el-table-column>
        <template slot-scope="scope">
          <!-- <el-button round size="mini" @click="chose(scope.row)"
            >选择</el-button
          > -->
          <el-button v-if="record.indexOf(scope.row.specialty_id) > -1" @click="chooseSpecialty(scope.row)" size="mini"
            type="primary">
            已选择
          </el-button>
          <el-button v-else @click="chooseSpecialty(scope.row)" size="mini">
            填报2
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="btn">
      <el-button class="btn1" size="medium" @click="onSubmit">确认</el-button>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Info",
  props: ["itemData", "batch"],
  data() {
    return {
      tableData: [],
      is_wenli: 1,
      record: [],
      name: "",
    };
  },
  computed: {
    ...mapState(["userInfo"]),
  },
  mounted() {
    this.is_wenli = this.userInfo.is_wenli
    if (this.itemData.college) {
      //院校
      // 专业
      this.itemData.specialty.forEach((item) => {
        this.record.push(item.specialty_id);
      });
      this.handleSelect(this.itemData.college, 0);
    }
  },
  methods: {
    handleSelect(item, stats = 1) {
      this.itemData.college = item;
      if (stats) {
        this.itemData.specialty = [];
        this.record = [];
      }
      this.name = item.name;

      let data = {
        batch: this.batch,
        select_code: item.select_code,
      };
      this.$fecth.post("/review/getSpList", data).then((res) => {
        this.tableData = res;
      });
    },

    querySearchAsync(queryString, cb) {
      let data = {
        name: queryString,
        batch: this.batch,
        is_wenli: this.is_wenli,
      };
      this.$fecth.post("/review/getCollegeList", data).then((res) => {
        if (res.length > 0) {
          cb(res);
        }
      });
    },
    chooseSpecialty(item) {
      let sp_id = this.record.indexOf(item.specialty_id);
      if (sp_id > -1) {
        this.record.splice(sp_id, 1);
        this.itemData.specialty.splice(sp_id, 1);
      } else {
        let splen = this.record.length;
        if (splen == 6) {
          this.$alert("每个志愿最多填报6个专业！", "提示", {
            confirmButtonText: "确定",
          });
          return false;
        } else {
          this.record.push(item.specialty_id);
          this.itemData.specialty.push(item);
        }
      }
      this.$forceUpdate();
    },
    onSubmit() {
      this.$emit("test");
    },
  },
};
</script>

<style lang="less" scoped>
.btn {
  margin-top: 20px;
  height: 30px;

  .btn1 {
    float: right;
  }
}
</style>>
